<template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row no-gutters class="ma-0">
                           <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Full Name</label>
                                            <v-text-field
                                            v-model="name"
                                            :rules="nameRules"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                           <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Phone Number</label>
                                            <v-text-field
                                            v-model="phone_no"
                                            :rules="phoneRules"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Company Name</label>
                                            <v-text-field
                                            v-model="company_name"
                                             :rules="[v => !!v || 'Company Name is required']"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="5" md="5" sm="12" xs="12" >
                                            <label for="">Company E-mail</label>
                                            <v-text-field
                                            v-model="email"
                                            :rules="emailRules"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                          <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Country</label>
                                            <v-text-field
                                            v-model="country"
                                             :rules="[v => !!v || 'Country  is required']"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Payment Volume</label>
                                            <v-text-field
                                            v-model="volume"
                                             :rules="[v => !!v || 'volume  is required']"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="11" md="11" sm="12" xs="12" >
                                            <label for="">Your Message</label>
                                            <v-textarea
                                            v-model="message"
                                            outlined
                                            :rules="messageRules"
                                            required
                                            ></v-textarea>
                                        </v-col>
                    

                    </v-row>

                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'ViewSalesContact',
    mixins: [coreMixin, colorMixin],
     data () {
       return {
            contact: null,
            name: '',
            email: '',
            company_name: '',
            phone_no: '',
            country: '',
            volume: '',
            message: '',
            notification: '',
            actionClass: '',
            formTitle: 'View Sales Contact details',
             valid: false,
             loading: false,
             contactID: null,
             countries: null,
            volumes: null,
             
       };
     },
     computed: {
        countriesData() {
            return !this.countries ? [] : this.countries;
        },
         paymentVolumes() {
            return !this.volumes ? [] : this.volumes;
        },
    },


    async mounted() {
       const id = this.$route.params.id;
       this.contactID = id;
        this.contact = await this.retrieveContact(id);
        const countries = await this.retrieveActiveCountries();
        this.countries = countries;
        const volumes = await this.retrieveActivePaymentVolumes();
        this.volumes = volumes;

        this.name = this.contact.name;
        this.email = this.contact.email;
        this.company_name = this.contact.company_name;
        this.phone_no = this.contact.phone_no;
        this.volume = this.contact.volume;
        this.message = this.contact.message;
        const countryDetails =  await this.retrieveCountry(this.contact.country);
        this.country  = countryDetails.name;

        const volumeDetails =  await this.retrievePaymentVolume(this.contact.volume);
        this.volume  = volumeDetails.range;

    },
    methods: {
        async retrieveContact(id) {
              const endpoint = `sales-contact/${id}`;

              const fullPayload = {
                  app: this.apiUrl,
                  endpoint,
              };

              try {
                  const response = await this.axiosGetRequest(fullPayload);
                  const contact = response.status === 200 ? response.data : null;
                  return contact;
              } catch (error) {
                return null;
              }
          },
          async save(){
              const formvalid = this.$refs.form.validate();
              if(!formvalid) {
                  return;
              }

                this.loading = true;

                    const payload = {
                          name: this.name,
                          country_code: this.country_code,
                      };
                      const fullPayload = {
                          app: this.apiUrl,
                          params: payload,
                          endpoint: `country/update/${this.contactID}`,
                      };
                        try {
                          const res = await this.axiosPutRequest(fullPayload);
                          this.loading = false;
                          this.notification = res.data ? 'Country updated successful' : 'Country failed to update';
                          this.actionClass = res.data  ? 'success' : 'error';
                       

                      } catch (error) {
                          this.notification = 'Country failed to update. Contact the admin';
                          this.actionClass = 'error';
                          this.loading = false;
                          return error.response;
                      }

          },
          cancel () {
           this.$router.push('/admin/countries');

          },
        }

}
</script>

<style>

</style>